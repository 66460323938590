<template>

    <div class="w-full mt-24 flex flex-col justify-start items-center max-w-3xl  px-2 md:p-0 space-y-12">

        <div class=" w-full ">
            <div class="text-6xl font-bold text-center">wallcharts.org</div>
            <div class="text-2xl text-center mt-2">for the good charts</div>
        </div>

        <ul class="list-disc list-inside space-y-2 mt-10">
            <li class=""><strong>Retro-inspired, offline tournament wallcharts:</strong> Fully customizable
                by timezone and language, designed to delight fans and support a good cause.</li>
            <li class=""><strong>Exclusive good cause partnership opportunity until 2028:</strong> Drive and measure
                real-world
                fan
                engagement through extended offline visibility and advanced QR scan system.</li>
            <li class=""><strong>Completely free for fans and good causes:</strong> A throwback,
                communal experience with no ads, no paywalls, and no sign-ups.</li>
        </ul>

        <div class="flex items-center justify-center w-full">
            <div class="w-1/3 border-b border-black"></div>
        </div>


        <div id="intro_section" class="w-full  flex flex-col ">
            <div class="text-2xl font-semibold">
                I'm Ben, welcome to wallcharts.org
            </div>
            <div class="w-full  flex flex-col md:flex-row mt-4">


                <div class="order-2 md:order-1 space-y-2  w-full  md:w-2/3">
                    <p class="">
                    In the early Summer of 2024, I made a Euros wall chart for my 10 year old daughter, because we
                    couldn't find one online that was in our time zone and was legible when printed at home. I
                    quickly popped it on the web and posted briefly about it on Reddit, offering to customise time
                    zones
                    for people.
                </p>
                <p>
                    Hundreds of positive comments and upvotes, and almost 10,000
                    unique site visitors later, my free, simple charts had connected with people in a surprisingly
                    human
                    way.
                </p>
                <p>
                    People really appreciated the time zone customisation. They also really liked no ads, no sign
                    up,
                    and no cookies. It particularly resonated with people living or working abroad.
                </p>
                <p>

                </p>
                <p>
                    Seeing the green shoots of potential in this simple, niche idea, I decided to keep the same
                    values
                    but engineer something more efficient and scalable that is solely focused on adding value to a good
                    cause.

                </p>
                <p>I have a career in socially-focussed finance and technology roles, and currently work for a small
                    family support charity, so building something that helps good causes felt natural.</p>


                </div>

                <div class="order-1 md:order-2 md:w-1/3 mb-6 md:mb-0 flex flex-col  items-center justify-start text-xs">
                    <div class=" grid grid-cols-4 md:grid-cols-1 gap-2  justify-items-center  max-w-xl  mt-4 text-xs">
                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <IconBallFootball class="h-10 w-10" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Euro 2024</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-2xl sm:text-3xl">
                                ~10K
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Unique visitors</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full   flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <IconBulb class="w-12 h-12" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Simple idea
                            </div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full   flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <IconCode class="w-12 h-12" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Engineered for scale
                            </div>
                        </div>




                        <!-- <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="relative">
                                <div class="text-3xl sm:text-4xl">
                                    <CreditCardIcon class="absolute h-6 w-6 top-3 right-3" />
                                </div>
                                <div class="text-3xl sm:text-4xl">
                                    <NoSymbolIcon class="h-12 w-12 " />
                                </div>
                            </div>
                            <div class="w-full flex items-start justify-center text-center  font-normal ">100% free
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>

        </div>


        <Button :parentFunction="showFeedback" :text="'See the feedback'" :buttonStyle="'primary-btn'"
            :icon="ArrowsPointingOutIcon" class="mt-6"></Button>



        <div id="value_to_fans_section" class="w-full  flex flex-col ">
            <div class="text-2xl font-semibold">
                The value to fans
            </div>
            <div class="w-full  flex flex-col md:flex-row mt-4">


                <div class="order-2 md:order-1 space-y-2  w-full  md:w-2/3">
                    <p>
                        Each offline tournament pack customised on
                        the website to a fan’s preferred timezone, language and layout, including support for
                        RTL
                        languages. All content is is designed for easy, print-at-home use.

                    </p>
                    <p>
                        The pack includes:
                    </p>
                    <ul class="list-disc list-inside ml-4">

                        <li>The classic tournament chart</li>
                        <li>A chronological fixture list</li>
                        <li>Team and tournament facts</li>
                        <li>Sweepstake tickets and games for children</li>
                    </ul>

                    <p>
                        It’s a fun way to enhance the fan experience across all ages.

                    </p>

                    <p>
                        Fans can create their pack with one click - no cookies, no online ads, no paywalls, and no
                        accounts
                        required. The content is stored via a unique, shareable link that allows seamless
                        mobile/desktop
                        transition.

                    </p>
                    <p class="hidden">
                        This brings back some of the communal spirit to global tournaments, something often lost in
                        today’s
                        isolated and fleeting digital experiences.
                    </p>
                    <p>
                        The easy language and timezone internationalisation feels thoughtful, culturally aware
                        and
                        inclusive to a global audience.
                    </p>
                    <p class="font-bold">
                        All of this is 100% free to fans, always.

                    </p>


                </div>

                <div class="order-1 md:order-2 md:w-1/3 mb-6 md:mb-0 flex flex-col  items-center justify-start text-xs">
                    <div class=" grid grid-cols-4 md:grid-cols-1 gap-2  justify-items-center  max-w-xl  mt-4 text-xs">
                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <ClockIcon class="h-10 w-10" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Global
                                timezones</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <LanguageIcon class="h-9 my-0.5 rounded-full " />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Major
                                languages</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full   flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <PrinterIcon class="h-10 w-10" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Print at
                                home
                            </div>
                        </div>



                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="relative">
                                <div class="text-3xl sm:text-4xl">
                                    <CreditCardIcon class="absolute h-6 w-6 top-3 right-3" />
                                </div>
                                <div class="text-3xl sm:text-4xl">
                                    <NoSymbolIcon class="h-12 w-12 " />
                                </div>
                            </div>
                            <div class="w-full flex items-start justify-center text-center  font-normal ">100% free
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <Button :parentFunction="pushDemo" :text="'Try the demo'" :buttonStyle="'primary-btn'"
            :icon="ArrowTopRightOnSquareIcon" class="hidden"></Button>

        <div id="value_to_good_cause_section" class="w-full  flex flex-col">
            <div class="text-2xl font-semibold">
                The value to a good cause
            </div>
            <div class="w-full  flex flex-col md:flex-row mt-4">
                <div class=" order-2 md:order-1 space-y-2  w-full  md:w-2/3">

                    <p>
                        This offline content gets extended visibility in real physical spaces with highly engaged
                        fans:
                        sitting on family fridges, bedroom cork boards and classroom walls for the entire length of
                        a
                        tournament.

                    </p>
                    <p>
                        Let’s leverage this extended visibility to benefit a good cause through encouraging direct
                        engagement and positive brand exposure.</p>
                    <p>


                        To drive direct engagement to the good cause, QR codes are thoughtfully placed throughout
                        the
                        content to encourage scans. The codes utilise geolocated redirections via wallcharts.org
                        that target
                        country specific web properties, such as donation pages or campaign information.
                    </p>
                    <p>

                        Anonymous metrics from web visits, shares and QR scans allows real-time assessment of fan
                        engagement
                        through a dedicated partner dashboard.
                    </p>
                    <p class="font-bold">
                        All of this is 100% free to a good cause, always.
                    </p>



                </div>
                <div class="order-1 md:order-2 md:w-1/3 mb-6 md:mb-0 flex flex-col  items-center justify-start text-xs">
                    <div class=" grid grid-cols-4 md:grid-cols-1 gap-2  justify-items-center  max-w-xl  text-xs">
                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <EyeIcon class="h-10 w-10" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Offline
                                presence</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <QrCodeIcon class="h-12 my-0.5 rounded-full p-1.5 " />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Advanced
                                QR system</div>
                        </div>

                        <div class=" min-h-20 sm:p-3 w-full   flex flex-col items-center justify-start">
                            <div class="text-3xl sm:text-4xl">
                                <ChartBarIcon class="h-9" />
                            </div>
                            <div class="w-full flex items-center justify-center text-center  font-normal mt-2">Metrics
                                dashboard
                            </div>
                        </div>



                        <div class=" min-h-20 sm:p-3 w-full    flex flex-col items-center justify-start">
                            <div class="relative">
                                <div class="text-3xl sm:text-4xl">
                                    <CreditCardIcon class="absolute h-6 w-6 top-3 right-3" />
                                </div>
                                <div class="text-3xl sm:text-4xl">
                                    <NoSymbolIcon class="h-12 w-12 " />
                                </div>
                            </div>
                            <div class="w-full flex items-start justify-center text-center  font-normal">100% free
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <Button :parentFunction="pushDemo" :text="'Try the demo'" :buttonStyle="'primary-btn'"
            :icon="ArrowTopRightOnSquareIcon" class="hidden"></Button>

        <div id="opportunity_to_good_cause_section"
            class="w-full  flex flex-col items-center justify-center md:flex-row">

            <div class="space-y-2  w-full  md:w-2/3">
                <div class="text-2xl font-semibold">
                    The opportunity for a good cause
                </div>
                <p>
                    Wallcharts.org presents an opportunity to collaborate exclusively with a good cause through
                    to the
                    2027 Women’s World Cup in an initial phase of exploration and development.
                </p>
                <p>

                    Together, the goal is to get as much offline content into as many spaces as possible,
                    driving
                    maximum engagement for the good cause. This is a chance to test and evolve the
                    wallcharts.org to its
                    full potential, measuring its value as a multiplier for a good cause.
                </p>
                <p>

                    I handle product evolution and engineering, while the good cause provides the reach.
                </p>
                <p>

                    If there are commercial partners interested in supporting the marketing and reach efforts
                    for the
                    good cause, they can also join in, with space available for them in the flexible QR system.
                </p>


            </div>
            <div
                class="w-full text-xs md:w-1/3 md:ml-4 max-w-80 flex flex-col justify-evenly items-center border border-black shadow-lg rounded-sm  p-3 space-y-3 mt-8 md:mt-8">
                <div class=" w-full flex items-center justify-center  flex-col  rounded-sm bg-white">
                    First phase:
                </div>
                <div
                    class="bg-black text-white w-full flex items-center justify-center py-2 flex-col  rounded-sm  font-semibold">
                    2025 Women's Euros
                </div>
                <div
                    class="bg-black text-white w-full flex items-center justify-center py-2 flex-col  rounded-sm  font-semibold">
                    2025 Women's Copa America
                </div>
                <div
                    class="bg-black text-white w-full  flex items-center justify-center py-2 flex-col rounded-sm  font-semibold">
                    2026 Men's African Cup
                </div>

                <div
                    class="bg-black text-white w-full flex items-center justify-center py-2 flex-col  rounded-sm  font-semibold">
                    2026 Men's World Cup
                </div>
                <div
                    class="bg-black text-white w-full flex items-center justify-center py-2 flex-col  rounded-sm  font-semibold">
                    2027 Men's Asian Cup
                </div>

                <div
                    class="bg-black text-white w-full flex items-center justify-center py-2 flex-col  rounded-sm  font-semibold">
                    2027 Women's World Cup
                </div>

            </div>


        </div>

        <div id="future_ops" class="w-full  flex flex-col ">
            <div class="text-2xl font-semibold">
                The potential
            </div>
            <div class="w-full  flex flex-col md:flex-row mt-4">


                <div class="order-2 md:order-1 space-y-2  w-full">
                    <p>
                        During this initial phase, we can explore how wallcharts.org can evolve. Perhaps in areas like:
                    </p>

                    <ul class="list-disc list-inside ml-4">

                        <li>Expanding to other quadrennial and biennial tournaments: Rugby, Cricket.</li>
                        <li>Expanding to annual tournaments: FA Cup, NFL Playoffs, NBA Playoffs, NHL Stanley Cup, Tennis
                            Majors.</li>
                        <li>Developing into a white-label platform as a service, available for all good causes and their
                            commercial partners.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="contact" class="w-full  flex flex-col ">
            <div class="text-2xl font-semibold">
                Let's discuss all of this
            </div>
            <div class="w-full  flex flex-col md:flex-row mt-4">


                <div class="order-2 md:order-1 space-y-2  w-full">
                    <p>
                        I'd be delighted to talk more about the potential benefits for a good cause, and how we can work
                        together to see how far we can push this.


                    </p>
                    <p>
                        You can reach me at ben@wallcharts.org.
                    </p>
                </div>
            </div>
        </div>

        <div id="mailing_list" class="hidden w-full  flex flex-col items-center justify-center space-y-4">

            <div class="w-full flex items-center justify-start">
                <div class="text-2xl font-semibold">
                    See what fans see, hop on the mailing list:
                </div>
            </div>
            <EmailSubscriber />
        </div>


    </div>



</template>

<script setup>

import { useRouter } from 'vue-router'
const router = useRouter()

import {markRaw} from 'vue'

import { ArrowsPointingOutIcon, ArrowTopRightOnSquareIcon, ClockIcon, PrinterIcon, CreditCardIcon, NoSymbolIcon, LanguageIcon, EyeIcon, QrCodeIcon, ChartBarIcon } from '@heroicons/vue/24/outline'
import { IconBulb, IconBallFootball, IconCode } from '@tabler/icons-vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'
import Button from '@shared/components/Button.vue'
import Feedback from '../components/Feedback.vue'


const testFunc = async () => {
    console.log('parent button action started')
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log('parent action completed')
            resolve()  // Resolve the promise after 2 seconds
        }, 2000)
    })
}

const pushDemo = async () => {
    router.push({ name: 'home' })
}

const showFeedback = () => {
    ui.openRightTray(markRaw(Feedback))
}

</script>