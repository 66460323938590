<template>
    <div class="p-6 max-w-4xl mx-auto">
      <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>

      <p class="mb-4">Welcome to printablewallcharts.com. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree to these terms, you should not use this website.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Use of Content</h2>
      <p class="mb-4">All content provided on this website is available for free use by anyone. You are free to use, share, and modify the content without any limitations, provided that you attribute the source fairly.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Fair Attribution</h2>
      <p class="mb-4">When using any content from this website, you must provide appropriate credit by linking back to printablewallcharts.com. The attribution should be clear and visible on the same page where the content is used.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">No Warranty</h2>
      <p class="mb-4">The content on this website is provided "as is" without any warranties, express or implied. While we strive to ensure the accuracy and reliability of the information, we do not guarantee its completeness or correctness.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Limitation of Liability</h2>
      <p class="mb-4">Under no circumstances shall printablewallcharts.com be liable for any direct, indirect, incidental, special, or consequential damages that result from the use or inability to use the content on this website.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Changes to Terms</h2>
      <p class="mb-4">We reserve the right to update or change these terms and conditions at any time. Any changes will be posted on this page with an updated effective date. Your continued use of the website after any changes indicates your acceptance of the new terms.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p class="mb-4">If you have any questions about these terms and conditions, you can contact us at <a href="mailto:hello@printablewallcharts.com" class="text-blue-500 underline">hello@printablewallcharts.com</a>.</p>

      <p class="mt-4">By using this website, you agree to these terms and conditions.</p>
    </div>
  </template>