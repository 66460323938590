export const translatedTeams = {
    'ENG': {
      'en': 'ENG',
      'en-GB': 'ENG',
      'en-US': 'ENG',
      'zh': '英格兰',
      'zh-CN': '英格兰',
      'zh-TW': '英格蘭',
      'hi': 'इंग्लैंड',  // Hindi in Devanagari script
      'hi-Latn': 'Inglainḍ',  // Hindi in Latin script
      'es': 'ING',
      'es-ES': 'ING',
      'es-MX': 'ING',
      'es-AR': 'ING',
      'fr': 'ANG',
      'fr-FR': 'ANG',
      'fr-CA': 'ANG',
      'ar': 'إنجلترا',
      'ar-SA': 'إنجلترا',
      'ar-EG': 'إنجلترا',
      'bn': 'ইংল্যান্ড',
      'pt': 'ING',
      'pt-PT': 'ING',
      'pt-BR': 'ING',
      'ru': 'АНГ',
      'ja': 'ENG',
      'pa': 'ਇੰਗਲੈਂਡ',
      'de': 'ENG',
      'de-DE': 'ENG',
      'it': 'ING',
      'it-IT': 'ING',
      'nl': 'ENG',
      'nl-NL': 'ENG',
      'sw': 'ENG',
      'yo': 'ENG',
      'ig': 'ENG',
      'ha': 'ENG',
      'id': 'ING',
      'ms': 'ENG',
      'fil': 'ING',
      'tl': 'ING'
    },
    'ITA': {
      'en': 'ITA',
      'en-GB': 'ITA',
      'en-US': 'ITA',
      'zh': '意大利',
      'zh-CN': '意大利',
      'zh-TW': '義大利',
      'hi': 'इटली',  // Hindi in Devanagari script
      'hi-Latn': 'Iṭlī',  // Hindi in Latin script
      'es': 'ITA',
      'es-ES': 'ITA',
      'es-MX': 'ITA',
      'es-AR': 'ITA',
      'fr': 'ITA',
      'fr-FR': 'ITA',
      'fr-CA': 'ITA',
      'ar': 'إيطاليا',
      'ar-SA': 'إيطاليا',
      'ar-EG': 'إيطاليا',
      'bn': 'ইতালি',
      'pt': 'ITA',
      'pt-PT': 'ITA',
      'pt-BR': 'ITA',
      'ru': 'ИТА',
      'ja': 'ITA',
      'pa': 'ਇਟਲੀ',
      'de': 'ITA',
      'de-DE': 'ITA',
      'it': 'ITA',
      'it-IT': 'ITA',
      'nl': 'ITA',
      'nl-NL': 'ITA',
      'sw': 'ITA',
      'yo': 'ITA',
      'ig': 'ITA',
      'ha': 'ITA',
      'id': 'ITA',
      'ms': 'ITA',
      'fil': 'ITA',
      'tl': 'ITA'
    },
    'GER': {
      'en': 'GER',
      'en-GB': 'GER',
      'en-US': 'GER',
      'zh': '德国',
      'zh-CN': '德国',
      'zh-TW': '德國',
      'hi': 'जर्मनी',  // Hindi in Devanagari script
      'hi-Latn': 'Jarmnī',  // Hindi in Latin script
      'es': 'ALE',
      'es-ES': 'ALE',
      'es-MX': 'ALE',
      'es-AR': 'ALE',
      'fr': 'ALL',
      'fr-FR': 'ALL',
      'fr-CA': 'ALL',
      'ar': 'ألمانيا',
      'ar-SA': 'ألمانيا',
      'ar-EG': 'ألمانيا',
      'bn': 'জার্মানি',
      'pt': 'ALE',
      'pt-PT': 'ALE',
      'pt-BR': 'ALE',
      'ru': 'ГЕР',
      'ja': 'GER',
      'pa': 'ਜਰਮਨੀ',
      'de': 'GER',
      'de-DE': 'GER',
      'it': 'GER',
      'it-IT': 'GER',
      'nl': 'GER',
      'nl-NL': 'GER',
      'sw': 'GER',
      'yo': 'GER',
      'ig': 'GER',
      'ha': 'GER',
      'id': 'GER',
      'ms': 'GER',
      'fil': 'ALE',
      'tl': 'ALE'
    },
    'SCO': {
      'en': 'SCO',
      'en-GB': 'SCO',
      'en-US': 'SCO',
      'zh': '苏格兰',
      'zh-CN': '苏格兰',
      'zh-TW': '蘇格蘭',
      'hi': 'स्कॉटलैंड',  // Hindi in Devanagari script
      'hi-Latn': 'Skoṭlaiṇḍ',  // Hindi in Latin script
      'es': 'ESC',
      'es-ES': 'ESC',
      'es-MX': 'ESC',
      'es-AR': 'ESC',
      'fr': 'ECO',
      'fr-FR': 'ECO',
      'fr-CA': 'ECO',
      'ar': 'اسكتلندا',
      'ar-SA': 'اسكتلندا',
      'ar-EG': 'اسكتلندا',
      'bn': 'স্কটল্যান্ড',
      'pt': 'ESC',
      'pt-PT': 'ESC',
      'pt-BR': 'ESC',
      'ru': 'ШОТ',
      'ja': 'SCO',
      'pa': 'ਸਕਾਟਲੈਂਡ',
      'de': 'SCO',
      'de-DE': 'SCO',
      'it': 'SCO',
      'it-IT': 'SCO',
      'nl': 'SCO',
      'nl-NL': 'SCO',
      'sw': 'SCO',
      'yo': 'SCO',
      'ig': 'SCO',
      'ha': 'SCO',
      'id': 'SCO',
      'ms': 'SCO',
      'fil': 'ESC',
      'tl': 'ESC'
    }
  }
