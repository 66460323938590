<template>
    <div>

        <div class="w-full flex items-center justify-center mt-2 text-3xl space-x-8 font-bold">
            <router-link :to="{name: 'home'}" @click="ui.closeRightTray()">
                Home (depr)
            </router-link>
            <router-link :to="{name: 'partners'}" @click="ui.closeRightTray()">
                Partners
            </router-link>
            <div>
                About
            </div>
            <div>
                Contact
            </div>
        </div>

        <div class="flex flex-col w-full items-start justify-center mt-8 space-y-4">
            <div class="font-semibold text-3xl">Current tournaments</div>
            <div class="grid grid-cols-2 place-items-center gap-4 ">
                <div v-for="a in 2" :key="a" class="h-72 w-72 border border-gray-400 rounded-xl shadow-lg "></div>
            </div>
        </div>

        <div class="flex flex-col w-full items-start justify-center mt-8 space-y-4">
            <div class="font-semibold text-3xl">Upcoming tournaments</div>
            <EmailSubscriber class="max-w-xl mt-6" />
            <div class="grid grid-cols-2 place-items-center gap-4 ">
                <div v-for="a in 5" :key="a" class="h-72 w-72 border border-gray-400 rounded-xl shadow-lg "></div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import EmailSubscriber from '@shared/components/EmailSubscriber.vue'


</script>