import { ref, watch } from "vue"
import { defineStore } from "pinia"
import api from '../api/api.js'
import { apiError } from '@shared/api/apiError.js'
import { useUIStore } from '@shared/stores/ui.js'
import { useLocationStore } from '@shared/stores/location.js'



export const useChartStore = defineStore('chartStore', () => {

    const ui = useUIStore()
    const location = useLocationStore()

    const chartUrl = ref(null)

    function getLatestChart() {
        const lastChart = localStorage.getItem('lastChartUrl')

        if (lastChart) {
            chartUrl.value = lastChart
        }
    }

    function clearChartUrl() {
        localStorage.removeItem('lastChartUrl')
        chartUrl.value = null
    }

    function createChartUrl(fileId) {
        return `https://charts.printablewallcharts.com/${fileId}.pdf`
    }

    async function createChart(svgContent) {
        try {
            const resp = await api.postCreateChart(svgContent)
            chartUrl.value = createChartUrl(resp.data.file_id)
            localStorage.setItem('lastChartUrl', chartUrl.value)
        } catch (error) {
            apiError(error, ui)
        }
    }

    const chartLayout = ref('cascade_ltr_landscape')

    const selectLayout = (layout) => {
        chartLayout.value = layout
        localStorage.setItem('chartLayoutPref', chartLayout.value)
    }

    const checkLayout = () => {
        const chartLayoutPref = localStorage.getItem('chartLayoutPref')

        if (chartLayoutPref) {
            chartLayout.value = chartLayoutPref
        }

        if (!chartLayoutPref && location.isRTL) {
            chartLayout.value = 'cascade_rtl_landscape'
        }
    }

    const qrMapping = {
        'commercial': {
            'fr': '/qr_nike_france.png',
            'cn': '/qr_nike_china.png',
            'global': '/qr_pwc_global.png'
        },
        'goodCause': {
            'fr': '/qr_msf_france.png',
            'us': '/qr_msf_us.png',
            'global': '/qr_pwc_global.png'
        }
    }

    const lookupQR = (partnerType, countryCode) => {

        if (countryCode.toLowerCase() in qrMapping[partnerType]) {
            return qrMapping[partnerType][countryCode.toLowerCase()]
        } else {
            return qrMapping[partnerType]['global']
        }
    }

    const redirects = {
        'euro2025': {
            'goodCause': {
                'fr': 'https://www.msf.fr/',
                'us': 'https://www.doctorswithoutborders.org/what-we-do/where-we-work',
                'global': 'https://msf.org.uk/'
            },
            'commercial': {
                'fr': 'https://www.nike.fr/',
                'cn': 'https://www.nike.com.cn',
                'global': 'https://nike.com/'
            },

        }
    }

    const lookupRedirect = (competition, partnerType, countryCode) => {

        if (!competition || !partnerType || !countryCode) {
            return null // to utilise internal router.push
        }

        const normalisedComp = competition
        const normalisedPartnerType = partnerType
        const normalisedCountryCode = countryCode.toLowerCase()

        console.log('in lookupRedirect function', normalisedComp, normalisedCountryCode, normalisedPartnerType)

        const competitionRedirects = redirects[normalisedComp]?.[normalisedPartnerType]

        if (competitionRedirects) {
            return competitionRedirects[normalisedCountryCode] || competitionRedirects['global']
        } else {
            console.warn(`error in params supplied to lookupRedirect`)
            return null
        }

    }


    return {
        chartUrl,
        createChart,
        createChartUrl,
        getLatestChart,
        clearChartUrl,
        chartLayout,
        selectLayout,
        checkLayout,
        lookupQR,
        lookupRedirect
    }
})