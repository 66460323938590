<template>
    <div id="app-top-bar"
        class="fixed  w-full flex items-center justify-between h-12 backdrop-blur-md bg-opacity-65 bg-white border-b border-gray-200 z-30 shadow-sm">

        <div id="top-bar-left-group" class=" hidden ml-3">
            <!-- <router-link :to="{name: 'home'}">
                <Squares2X2Icon class="h-8 w-8 text-black"/>
            </router-link> -->
            <button @click="ui.openRightTray(markRaw(Menu))">
                <IconLayoutGrid class="h-8 w-8 mt-1.5 "/>
            </button>
        </div>

        <div id="top-bar-right-group"
            class="flex z-10 w-full items-center justify-end  mr-3 text-sm sm:text-base max-h-8 overflow-x-visible space-x-3 ">
            <button
                class="flex items-center justify-between py-1 px-3 h-9 text-black"
                @click="shareNative()">
                <IconShare2 class="h-6 w-6" />
            </button>
        </div>
    </div>
</template>

<style scoped></style>

<script setup>

import { onMounted, ref, computed, markRaw } from 'vue'

import { IconLayoutGrid, IconShare2 } from '@tabler/icons-vue'

import rollbar from '../../rollbarClient.js'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()


import Menu from './Menu.vue'


const isShareSupported = ref(false)


onMounted(() => {
    isShareSupported.value = !!navigator.share
})



async function shareNative() {
    if (navigator.share) {

        await navigator.share({
            title: 'wallcharts.org: for the good tournament charts',
            text: 'Tournament wallcharts in your timezone and language, supporting a good cause',
            url: "https://wallcharts.org",
        })
        rollbar.info("shared via native")
    } else {
        sendEmail()
        rollbar.info("shared non-native via email")
    }
}

function sendEmail() {

    const recipient = null
    const subject = encodeURIComponent("wallcharts.org: for the good tournament charts")
    const body = encodeURIComponent("Tournament wallcharts in your timezone and language, supporting a good cause")

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`

    window.location.href = mailtoLink
}

</script>