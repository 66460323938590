import axios from "axios"


const sharedApi = axios.create({
    baseURL: import.meta.env.VITE_SERVER_ROOT,
    timeout: 4000

})

// apiV1.interceptors.request.use((config) => {
//     config.headers['X-Request-ID'] = uuidv4()
//     return config
// })

// apiV1.interceptors.response.use(
//     response => {
//         localStorage.setItem('lastReload', new Date().toDateString())
//         return response
//     },
//     error => {
//         return Promise.reject(error)
//     }
// )


export default {
    async postCreateSubscriber(email) {
        return sharedApi.post(
            "/shared/subscribers",
            { email: email },
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                }
            }
        )
    },
    async patchConfirmSubscriber(confirmCode) {
        return sharedApi.patch(
            "/shared/subscribers",
            { confirm_code: confirmCode },
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                }
            }
        )
    },
    async deleteRemoveSubscriber(unsubCode) {
        return sharedApi.delete(
            "/shared/subscribers",
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                },
                data: { unsub_code: unsubCode },
            }
        )
    },
    async getLocationFromApi() {
        try {
            const resp = await axios.get(
                "http://ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,timezone,offset",
            )
            return resp.data
        } catch(error) {
            console.error("error fetching location info", error)
        }
    },
}
