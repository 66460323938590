import { createRouter, createWebHistory } from "vue-router";
import HomeView from '../views/HomeView.vue'
import PartnerView from '../views/PartnerView.vue'
import QrRedirect from '../views/QrRedirect.vue'

import { useSubscriberStore } from '@shared/stores/subscriber.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/email",
      name: "home",
      component: HomeView,
      beforeEnter: async (to, from, next) => {
        const sub = useSubscriberStore()

        const confirmCode = to.query.confirm_code
        const unsubCode = to.query.unsub_code

        if (confirmCode) {
          try {
            await sub.confirmSubscriber(confirmCode, import.meta.env.VITE_APP_ID)
            next({ path: '/'})
            return
          } catch (error) {
            console.error('Router: Error confirming subscriber:', error)
          }
        }

        if (unsubCode) {
          try {
            await sub.removeSubscriber(unsubCode, import.meta.env.VITE_APP_ID)
            next({ path: '/'})
            return
          } catch (error) {
            console.error('Router: Error removing subscriber:', error)
          }
        }


        next() // Proceed to the HomeView component
      }
    },
    {
      path: "/qr",
      name: "qr",
      component: QrRedirect,
    },
    {
      path: "/",
      name: "partners",
      component: PartnerView,
    },
  ],
});

export default router
