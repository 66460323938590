<template>
    <div class="w-full  flex flex-col justify-start  items-center ">
        <div class="flex max-w-2xl w-full space-x-2 items-center justify-center h-64 ">

            <div v-if="!chart.chartUrl" id="forms"
                class="pl-2 flex flex-col w-full items-center justify-center space-y-2">
                <div class="relative w-full flex items-center justify-evenly space-x-2 mr-2 sm:mr-0">
                    <ClockIcon class="w-14 sm:w-16 sm:mr-2 " />
                    <select id="tz-select" v-model="location.selected_timezone"
                        class=" relative w-full h-10 font-semibold px-3 border border-black bg-white rounded-sm  shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                        <option v-for="tz in timezones" :key="tz" :value="tz.zone">
                            {{ tz.zone }}
                        </option>
                    </select>
                    <LoadSpin v-if="!location.selected_timezone" class="h-4 w-4 absolute top-2 left-0 z-50"></LoadSpin>
                    <div class="w-full relative">
                        <input v-model="location.tzSearchTerm" placeholder="Search timezones... "
                            class="placeholder:text-black placeholder:font-extralight w-full h-10  px-3 border border-black bg-white rounded-sm shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                        </input>
                        <ul v-if="location.tzSearchTerm"
                            class="absolute border border-blackrounded-sm shadow-lg top-8 left-0 bg-white  max-h-56 overflow-auto z-50">
                            <li v-for="tz in location.filteredTimezones" :key="tz" @click="location.selectTimezone(tz)"
                                class="hover:bg-black hover:text-white px-3 py-2 rounded-sm transition ease-in-out duration-300 cursor-pointer">
                                {{ location.highlightSearch(tz) }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="relative w-full flex items-center justify-evenly space-x-2 mr-2 sm:mr-0">
                    <LanguageIcon class="w-14 sm:w-16 sm:mr-2 " />
                    <select id="lang-select" v-model="language.lang"
                        class="w-full py-2 px-3 h-10 font-semibold border border-black bg-white rounded-sm shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                        <option v-for="l in language.supported_langs" :key="l" :value="l.code">
                            {{ l.nativeDescription }}
                        </option>
                    </select>
                    <LoadSpin v-if="!language.lang" class="h-4 w-4 absolute top-2 left-0 z-50"></LoadSpin>
                    <div class="w-full relative">
                        <input v-model="language.langSearchTerm" placeholder="Search languages..."
                            class="placeholder:text-black placeholder:font-extralight w-full h-10 px-3 border border-black bg-white rounded-sm shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                        </input>

                        <ul v-if="language.langSearchTerm"
                            class="absolute border border-blackrounded-sm shadow-lg top-8 left-0 bg-white  max-h-56 overflow-auto z-50">
                            <li v-for="l in language.filteredLanguages" :key="l" @click="language.selectLanguage(l)"
                                class="hover:bg-black hover:text-white px-3 py-2 rounded-sm transition ease-in-out duration-300 cursor-pointer">
                                {{ language.highlightSearch(l) }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="message_entry" class="w-full flex items-center justify-evenly space-x-2 mr-2 sm:mr-0">
                    <ChatBubbleLeftIcon class="w-6 sm:w-8  sm:mr-2" />
                    <input placeholder="Your custom message..."
                        class="placeholder:text-black placeholder:font-extralight w-full h-10 px-3 border border-black bg-white rounded-sm shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                    </input>
                </div>
                <div class="w-full flex items-center justify-start space-x-2 mr-2 sm:mr-0">



                    <IconShirt :class="'sm:w-8 sm:h-8 sm:mr-2'">
                    </IconShirt>


                    <select id="team-select"
                        class="font-semibold w-full py-2 px-3 h-10 border border-black bg-white rounded-sm shadow-sm focus:outline-none focus:ring-gray-400 focus:border-border-blackgray-400 sm:text-sm">
                        <option v-for="l in ['-', 'England', 'Scotland']" :key="l" :value="l">
                            {{ l }}
                        </option>
                    </select>
                </div>
                <div class="w-full flex items-center justify-start space-x-2 mr-2 sm:mr-0">



                    <IconArrowsDiff :class="'sm:w-8 sm:h-8 sm:mr-2'" />

                    <div class="w-full  rounded-sm h-10 flex justify-evenly items-center">
                        <div class="w-full border-2 border-black flex justify-center h-full items-center font-semibold">LTR</div>
                        <div class="w-full border border-black border-l-transparent flex justify-center h-full items-center font-extralight">LTR</div>
                        <div class="w-full flex border border-l-transparent border-black justify-center h-full items-center font-extralight">LTR</div>
                    </div>
                </div>



                <Button :parentFunction="dispatchCreateChart" :text="'Create my chart'" :buttonStyle="'secondary-btn'"
                    class="!mt-6"></Button>
            </div>



            <div v-if="chart.chartUrl"
                class="w-full flex flex-col justify-center items-center space-y-8 transition ease-in-out duration-1000">
                <div class="flex justify-center space-x-4  items-center">

                    <div
                        class=" flex w-56 h-10 md:w-2/3  items-center justify-between border border-black rounded-sm space-x-2">
                        <div
                            class="relative h-10 whitespace-nowrap overflow-hidden text-ellipsis flex items-center px-4   text-sm font-extralight">
                            {{ chart.chartUrl }}
                            <div
                                class="absolute -right-2 h-8 w-16  bg-gradient-to-r from-transparent to-white opacity-95">
                            </div>
                        </div>
                        <button @click="copyUrl" v-if="!copied"
                            class="border-l px-2  w-14 flex items-center justify-center">
                            <DocumentDuplicateIcon class="h-5 text-black" />

                        </button>
                        <button @click="copyUrl" v-if="copied"
                            class="border-l px-2 w-14 flex items-center justify-center">
                            <CheckCircleIcon class="h-5 text-black" />

                        </button>
                    </div>
                    <button @click="chart.clearChartUrl"
                        class=" border border-black rounded-sm w-16 h-10 flex items-center justify-center text-xs">
                        <ArrowPathIcon class="h-6 text-black" />
                    </button>
                </div>

                <div
                    class="w-full grid grid-cols-2 md:flex md:flex-row gap-2 justify-items-center items-center md:justify-evenly md:items-center text-xs">


                    <button @click="downloadChart"
                        class="h-10 py-2  text-white rounded-sm w-32 bg-black flex justify-center items-center">
                        <LoadSpin class="w-6 h-6" v-if="isLoading" />
                        <div v-else class="w-full flex items-center justify-between">
                            <div class="pl-3">

                                Download
                            </div>
                            <div class="w-10 flex items-center justify-center ">

                                <ArrowDownCircleIcon class="text-white w-6" />
                            </div>
                        </div>
                    </button>
                    <button @click="downloadChart"
                        class="h-10 py-2  text-white rounded-sm w-32 bg-black flex justify-center items-center">
                        <LoadSpin class="w-6 h-6" v-if="isLoading" />
                        <div v-else class="w-full flex items-center justify-between">
                            <div class="pl-3">

                                Share
                            </div>
                            <div class="w-10 flex items-center justify-center ">

                                <ArrowUpOnSquareIcon class="text-white w-5" />
                            </div>
                        </div>
                    </button>
                    <button @click="downloadChart"
                        class="h-10 py-2  text-white rounded-sm w-32 bg-black flex justify-center items-center">
                        <LoadSpin class="w-6 h-6" v-if="isLoading" />
                        <div v-else class="w-full flex items-center justify-between">
                            <div class="pl-3">

                                Order print
                            </div>
                            <div class="w-10 flex items-center justify-center ">

                                <TruckIcon class="text-white w-5" />
                            </div>
                        </div>
                    </button>
                    <button @click="downloadChart"
                        class="h-10 py-2  text-white rounded-sm w-32 bg-black flex justify-center items-center">
                        <LoadSpin class="w-6 h-6" v-if="isLoading" />
                        <div v-else class="w-full flex items-center justify-between">
                            <div class="pl-3">

                                Email
                            </div>
                            <div class="w-10 flex items-center justify-center ">

                                <EnvelopeIcon class="text-white w-5" />
                            </div>
                        </div>
                    </button>
                </div>
            </div>

        </div>



        <div id="chart-wrapper-only"
            class=" mt-12 rounded-lg max-w-4xl  overflow-auto border border-blackborder-border-blackgray-200  shadow-lg shadow-gray-400 w-full">
            <div id="chart-content" ref="chartOuter" data-role="the actual page" class=" relative p-1.5 "
                :style="chartContentStyle">
                <div
                    class="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-100 via-white to-slate-200 opacity-25 ">
                </div>

                <div v-if="chart.chartLayout === 'cascade_ltr_landscape'">
                    <svg id="placeholders" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 846 595.28">

                        <defs>
                            <linearGradient id="White_Black" data-name="White, Black" x1="599.31" y1="531.69"
                                x2="657.31" y2="531.69" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#fff" />
                                <stop offset="1" stop-color="#231f20" />
                            </linearGradient>
                            <linearGradient id="White_Black-2" data-name="White, Black" x1="741.31" y1="528.69"
                                x2="799.31" y2="528.69" xlink:href="#White_Black" />
                        </defs>
                        <text transform="translate(555.5 33.55)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 24px;">
                            <tspan x="0" y="0">{{ chart_title }}</tspan>
                        </text>
                        <rect x="3.7" y="3.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,62.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,48.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,101.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,87.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,140.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,126.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,180.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,166.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,219.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,205.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,258.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,244.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <text transform="translate(9.68 50.66) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#1</tspan>
                        </text>
                        <text transform="translate(9.68 90) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#2</tspan>
                        </text>
                        <text transform="translate(716.31 30.28)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 12px;">
                            <tspan x="0" y="0">{{ location.get_oset(location.selected_timezone) }}</tspan>
                        </text>
                        <text transform="translate(29.48 43.57) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.home_translated }}</tspan>
                        </text>
                        <text transform="translate(29.48 56.94) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.away_translated }}</tspan>
                        </text>
                        <text transform="translate(138.33 42.57) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.game_date }}</tspan>
                        </text>
                        <text transform="translate(138.33 55.57) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.game_time }}</tspan>
                        </text>
                        <rect x="3.7" y="311.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,370.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,356.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,409.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,395.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,448.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,434.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,488.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,474.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,527.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,513.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M129.8,566.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M112.55,552.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <text transform="translate(9.68 358.66) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#3</tspan>
                        </text>
                        <text transform="translate(9.68 398) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#4</tspan>
                        </text>
                        <rect x="198.7" y="4.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,63.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,49.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,102.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,88.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,141.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,127.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,181.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,167.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,220.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,206.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M324.8,259.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M307.55,245.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect id="qr" x="599.31" y="502.69" width="58" height="58"
                            style="fill: url(#White_Black); stroke: #231f20; stroke-miterlimit: 10;" />
                        <rect id="qr-2" data-name="qr" x="741.31" y="499.69" width="58" height="58"
                            style="fill: url(#White_Black-2); stroke: #231f20; stroke-miterlimit: 10;" />
                        <text transform="translate(635.31 69.28)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 12px;">
                            <tspan x="0" y="0">{{ custom_message }}</tspan>
                        </text>
                        <text transform="translate(28.53 83.67) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.home_translated }}</tspan>
                        </text>
                        <text transform="translate(28.53 97.03) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.away_translated }}</tspan>
                        </text>
                        <text transform="translate(139.31 82.85) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.game_date }}</tspan>
                        </text>
                        <text transform="translate(139.31 95.85) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.game_time }}</tspan>
                        </text>
                        <text transform="translate(27.64 351.03) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m3.home_translated }}</tspan>
                        </text>
                        <text transform="translate(27.64 364.39) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m3.away_translated }}</tspan>
                        </text>
                        <text transform="translate(134.48 351.21) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m3.game_date }}</tspan>
                        </text>
                        <text transform="translate(134.48 364.21) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m3.game_time }}</tspan>
                        </text>
                        <text transform="translate(26.68 391.13) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m4.home_translated }}</tspan>
                        </text>
                        <text transform="translate(26.68 404.49) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m4.away_translated }}</tspan>
                        </text>
                        <text transform="translate(135.46 391.49) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m4.game_date }}</tspan>
                        </text>
                        <text transform="translate(135.46 404.49) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m4.game_time }}</tspan>
                        </text>
                        <rect x="196.7" y="312.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,371.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,357.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,410.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,396.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,449.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,435.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,489.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,475.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,528.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,514.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M322.8,567.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M305.55,553.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="393.71" y="63.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="393.9" y="186.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="394.71" y="309.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="394.9" y="432.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="529.71" y="157.85" width="125.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="529.9" y="311.62" width="125.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="666.11" y="232.69" width="172.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M510.93,163.55c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M489.31,143.62h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M511.12,286.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M489.5,266.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M512.12,415.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M490.5,395.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M509.12,536.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M487.5,516.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M645.12,289.62c0,1.65-1.35,3-3,3h-21.81c-1.65,0-3-1.35-3-3v-49.93c0-1.65,1.35-3,3-3h21.81c1.65,0,3,1.35,3,3v49.93Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M617.31,264.66h27.81"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M645.21,443.66c0,1.65-1.35,3-3,3h-21.81c-1.65,0-3-1.35-3-3v-49.93c0-1.65,1.35-3,3-3h21.81c1.65,0,3,1.35,3,3v49.93Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M617.4,418.69h27.81"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M830.76,364.68c0,1.65-1.35,3-3,3h-28.91c-1.65,0-3-1.35-3-3v-69.97c0-1.65,1.35-3,3-3h28.91c1.65,0,3,1.35,3,3v69.97Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M795.85,329.69h34.91"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                    </svg>
                </div>

                <div v-if="chart.chartLayout === 'cascade_rtl_landscape'">
                    <svg id="placeholders" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 846 595.28">
                        <defs>
                            <linearGradient id="White_Black" data-name="White, Black" x1="102.25" y1="528.69"
                                x2="160.25" y2="528.69" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#fff" />
                                <stop offset="1" stop-color="#231f20" />
                            </linearGradient>
                            <linearGradient id="White_Black-2" data-name="White, Black" x1="239.25" y1="524.69"
                                x2="297.25" y2="524.69" xlink:href="#White_Black" />
                        </defs>
                        <text transform="translate(66.19 33.21)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 24px;">
                            <tspan x="0" y="0">{{ chart_title }}</tspan>
                        </text>
                        <rect x="501.64" y="2.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,57.55c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,43.69h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,96.89c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,83.03h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,136.23c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,122.37h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,175.57c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,161.71h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,214.91c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,201.05h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M567.87,254.25c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M550.63,240.39h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <text transform="translate(667.45 45.86) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#1</tspan>
                        </text>
                        <text transform="translate(667.45 85.2) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 8.99px;">
                            <tspan x="0" y="0">#2</tspan>
                        </text>
                        <text transform="translate(227 29.95)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 12px;">
                            <tspan x="0" y="0">{{ location.get_oset(location.selected_timezone) }}</tspan>
                        </text>
                        <text transform="translate(573.2 37.03) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.home_translated }}</tspan>
                        </text>
                        <text transform="translate(573.2 50.39) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.away_translated }}</tspan>
                        </text>
                        <text transform="translate(505.33 37.67) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.game_date }}</tspan>
                        </text>
                        <text transform="translate(505.33 50.67) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m1.game_time }}</tspan>
                        </text>
                        <rect x="501.64" y="310.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,369.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,355.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,408.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,394.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,447.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,433.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,487.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,473.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,526.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,512.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M627.74,565.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M610.49,551.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="696.64" y="3.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,62.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,48.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,101.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,87.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,140.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,126.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,180.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,166.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,219.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,205.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M822.74,258.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M805.49,244.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect id="qr" x="102.25" y="499.69" width="58" height="58"
                            style="fill: url(#White_Black); stroke: #231f20; stroke-miterlimit: 10;" />
                        <rect id="qr-2" data-name="qr" x="239.25" y="495.69" width="58" height="58"
                            style="fill: url(#White_Black-2); stroke: #231f20; stroke-miterlimit: 10;" />
                        <text transform="translate(146 68.95)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 12px;">
                            <tspan x="0" y="0">{{ custom_message }}</tspan>
                        </text>
                        <text transform="translate(572.25 77.13) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.home_translated }}</tspan>
                        </text>
                        <text transform="translate(572.25 90.49) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.away_translated }}</tspan>
                        </text>
                        <text transform="translate(506.3 77.95) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.game_date }}</tspan>
                        </text>
                        <text transform="translate(506.3 90.95) scale(.96 1)"
                            style="fill: #231f20; font-family: ArialMT, Arial; font-size: 10px;">
                            <tspan x="0" y="0">{{ schedule.gameSchedule.m2.game_time }}</tspan>
                        </text>
                        <rect x="694.64" y="311.19" width="183.22" height="281" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,370.01c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,356.15h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,409.35c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,395.49h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,448.69c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,434.83h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,488.03c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,474.17h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,527.37c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,513.51h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M820.74,566.71c0,1.65-1.35,3-3,3h-11.24c-1.65,0-3-1.35-3-3v-27.72c0-1.65,1.35-3,3-3h11.24c1.65,0,3,1.35,3,3v27.72Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M803.49,552.85h17.24"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="361.46" y="62.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="361.65" y="185.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="362.46" y="308.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="362.65" y="431.22" width="125.19" height="114.94" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="224.65" y="156.85" width="125.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="224.85" y="310.62" width="125.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <rect x="47.15" y="233.85" width="172.19" height="143.7" rx="7.55" ry="7.55"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M389.06,167.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M367.44,147.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M390.06,290.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M368.44,270.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M390.06,415.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M368.44,395.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M390.06,536.62c0,1.65-1.35,3-3,3h-15.62c-1.65,0-3-1.35-3-3v-39.86c0-1.65,1.35-3,3-3h15.62c1.65,0,3,1.35,3,3v39.86Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M368.44,516.69h21.62"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M259.16,289.66c0,1.65-1.35,3-3,3h-21.81c-1.65,0-3-1.35-3-3v-49.93c0-1.65,1.35-3,3-3h21.81c1.65,0,3,1.35,3,3v49.93Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M231.34,264.69h27.81"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M260.16,444.66c0,1.65-1.35,3-3,3h-21.81c-1.65,0-3-1.35-3-3v-49.93c0-1.65,1.35-3,3-3h21.81c1.65,0,3,1.35,3,3v49.93Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M232.34,419.69h27.81"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path
                            d="M87.7,362.68c0,1.65-1.35,3-3,3h-28.91c-1.65,0-3-1.35-3-3v-69.97c0-1.65,1.35-3,3-3h28.91c1.65,0,3,1.35,3,3v69.97Z"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                        <path d="M52.8,327.69h34.91"
                            style="fill: none; stroke: #231f20; stroke-miterlimit: 10; stroke-width: .5px;" />
                    </svg>
                </div>


                <img class="scale-50 hidden" id="goodCauseQR" src="/qr_good_cause.png" :style="goodCauseQRStyle" />
                <img class="scale-50 hidden" id="commercialQR" src="/qr_commercial.png" :style="commercialQRStyle" />

            </div>
        </div>

    </div>

</template>


<script setup>
import { onMounted, ref, watch, computed } from 'vue'

import { useChartStore } from '../../stores/chart.js'
const chart = useChartStore()

import LoadSpin from '@shared/components/LoadSpin.vue'
import Button from '@shared/components/Button.vue'

import { useLocationStore } from '@shared/stores/location.js';
const location = useLocationStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useScheduleStore } from '../../stores/schedule.js'
const schedule = useScheduleStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { ArrowPathIcon, CheckCircleIcon, DocumentDuplicateIcon } from '@heroicons/vue/24/solid'
import { ArrowUpOnSquareIcon, EnvelopeIcon, TruckIcon, ArrowDownCircleIcon, LanguageIcon, ChatBubbleLeftIcon } from '@heroicons/vue/24/outline'
import { ClockIcon } from '@heroicons/vue/24/outline'
import { IconArrowsDiff, IconShirt } from '@tabler/icons-vue'


const isLoading = ref(false)
const chartOuter = ref(null)
const chartContentStyle = "position: relative;"

const qrLayoutStyles = {
    'cascade_ltr_landscape': {
        goodCause: "position: absolute; bottom: 28px; right: 60px;  height: 100px; width: 100px",
        commercial: "position: absolute; bottom: 28px; right: 230px;  height: 100px; width: 100px"
    },
    'cascade_rtl_landscape': {
        goodCause: "position: absolute; bottom: 28px; left: 60px;  height: 100px; width: 100px",
        commercial: "position: absolute; bottom: 28px; left: 230px;  height: 100px; width: 100px"
    }
}

const goodCauseQRStyle = computed(() => {
    return qrLayoutStyles[chart.chartLayout].goodCause
})

const commercialQRStyle = computed(() => {
    return qrLayoutStyles[chart.chartLayout].commercial
})

const copied = ref(false)

const copyUrl = async () => {
    copied.value = true
    await navigator.clipboard.writeText(chart.chartUrl)

    setTimeout(() =>
        copied.value = false, 4000
    )
}

const timezones = location.getTimezoneDetails()

const custom_message = ref(null)



function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

async function downloadChart() {
    window.open(chart.chartUrl, '_blank')
}

async function dispatchCreateChart() {
    const start = new Date()

    const element = document.querySelector('#chart-content')
    let content = element.outerHTML

    const goodCauseUrl = chart.lookupQR('goodCause', location.lookupCountryCode(location.selected_timezone))
    const goodCauseResponse = await fetch(goodCauseUrl)
    const goodCauseBlob = await goodCauseResponse.blob()
    const goodCauseBase64Image = await toBase64(goodCauseBlob)
    content = content.replace('src="/qr_good_cause.png"', `src='${goodCauseBase64Image}'`)

    // commercial
    const commercialUrl = chart.lookupQR('commercial', location.lookupCountryCode(location.selected_timezone))
    const commercialResponse = await fetch(commercialUrl)
    const commercialBlob = await commercialResponse.blob()
    const commercialBase64Image = await toBase64(commercialBlob)
    content = content.replace('src="/qr_commercial.png"', `src='${commercialBase64Image}'`)

    await chart.createChart(content)

    const end = new Date()
    console.log(`chart load time: ${end - start}`)
}

const chart_title = "DEMO 2025"


onMounted(() => {
    schedule.localiseGameSchedule(schedule.gameSchedule, location.selected_timezone)
})


</script>
