<template>
  <header>
    <div>
    </div>
  </header>

  <div class="w-full">
    <RightTray class="z-30" :rightTrayIsOpen="ui.rightTrayIsOpen" :openRightTray="ui.openRightTray"
      :closeRightTray="ui.closeRightTray" :rightTrayComponent="ui.rightTrayComponent"
      :rightTrayComponentProps="ui.rightTrayComponentProps" :rightTrayBackNavComponent="ui.rightTrayBackNavComponent"
      :rightTrayBackNavProps="ui.rightTrayBackNavProps" />
  </div>

  <div id="layout" class="flex flex-col w-full h-screen justify-between items-center ">
    <div class="w-full flex flex-col  justify-start">

      <div id="app-top-bar" class="w-full">
        <TopNav />
      </div>

      <div id="main-content" class="max-w-4/5 flex flex-col justify-start items-center">
        <RouterView />
      </div>
    </div>

    <div id="footer-bar" class="w-full">
      <Footer />
    </div>
  </div>

  <transition enter-active-class="transition-opacity ease-out duration-1000" enter-from-class="opacity-0"
    enter-to-class="opacity-100" leave-active-class="transition-opacity ease-in duration-1000"
    leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div class="fixed bottom-4 right-4 space-y-1" v-if="ui.messages.length">
      <FlashMessage v-for="message in ui.messages" :key="message" :message="message.message" :isError="message.isError"
         />
    </div>
  </transition>

</template>


<script setup>
import { RouterView } from "vue-router"
import { onMounted } from 'vue'


import TopNav from './components/layout/TopNav.vue'
import Footer from './components/layout/Footer.vue'
import RightTray from '@shared/components/RightTray.vue'

import FlashMessage from '@shared/components/FlashMessage.vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLocationStore } from '@shared/stores/location.js'
const location = useLocationStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

import { useChartStore } from './stores/chart.js'
const chart = useChartStore()


onMounted(() => {
  language.getLanguage()
  location.getLocation()
  chart.getLatestChart()
  chart.checkLayout()
})

</script>
