<template>
    <div id="footer-bar" class="w-full flex items-center justify-end px-4 pb-2 space-x-4 text-xs mt-12 ">
        <button @click="ui.openRightTray(markRaw(Privacy))" class="flex items-center">
            <span class="underline">No cookies!!</span>
            <span class="text-xl ml-1 no-underline">🍪</span>
        </button>
        <button class="underline" @click="ui.openRightTray(markRaw(Terms))">Terms & Conditions</button>
        <button class="underline" @click="ui.openRightTray(markRaw(Privacy))">Privacy Policy</button>
    </div>
</template>

<script setup>

import { markRaw } from 'vue'

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import Privacy from '@shared/components/Privacy.vue'
import Terms from '@shared/components/Terms.vue'

</script>