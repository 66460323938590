<template>
    <div class="p-12 w-full flex flex-col items-start justify-center">
      <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p class="mb-4"><strong>Effective Date:</strong> May 2024</p>

      <p class="mb-4">At printablewallcharts.com, your privacy is extremely important to us. This privacy policy outlines the types of information we collect, how we use it, and the measures we take to ensure your data remains confidential and secure.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Information We Collect</h2>
      <ul class="list-disc list-inside mb-4">
        <li><strong>IP Addresses:</strong> We log IP addresses and routes visited for the purpose of analyzing traffic and calculating advertising return on investment (ROI). This information is collected automatically when you visit our website.</li>
        <li><strong>Route Information:</strong> We track the routes (URLs) you visit on our website to understand user behavior and improve our services.</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-6 mb-2">What We Do NOT Collect</h2>
      <ul class="list-disc list-inside mb-4">
        <li>We do not collect any personal data such as your name, email address, or contact information.</li>
        <li>We do not install cookies or any other tracking technologies on your device.</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Use of Collected Information</h2>
      <p class="mb-4">The information we collect (IP addresses and routes visited) is used solely for the following purposes:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Analyzing website traffic to understand user patterns and preferences.</li>
        <li>Calculating the effectiveness and ROI of our advertising campaigns.</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Data Storage</h2>
      <p class="mb-4">We do not store IP address data ourselves. Instead, we use a third-party service called Rollbar for this purpose. Rollbar helps us monitor and track errors and usage patterns without storing personal data.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Data Sharing and Selling</h2>
      <ul class="list-disc list-inside mb-4">
        <li>We do not sell, trade, or otherwise transfer your information to outside parties.</li>
        <li>We do not purchase any data from external sources.</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Data Security</h2>
      <p class="mb-4">We implement a variety of security measures to maintain the safety of your information. Despite our efforts, please be aware that no security measures are perfect or impenetrable.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Changes to Our Privacy Policy</h2>
      <p class="mb-4">We reserve the right to update or change our privacy policy at any time. Any changes will be posted on this page with an updated effective date.</p>

      <h2 class="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p class="mb-4">If you have any questions about this privacy policy, you can contact us at <a href="mailto:hello@printablewallcharts.com" class="text-blue-500 underline">hello@printablewallchart.com</a>.</p>

      <p>By using our website, you consent to our privacy policy.</p>
    </div>
  </template>