<template>
  <div class=" w-full  flex flex-col items-center justify-start  mt-20 ">

    <Hero :tournamentName="`Demo 2025`" class="" />

    <DynamicChart class="w-4xl mt-14 text-xs sm:text-sm" />

    <div class="text-4xl mt-16  font-semibold max-w-xl w-full flex items-start justify-start">Know about future tournaments</div>
    <EmailSubscriber class="max-w-xl mt-6" />


    <div class="w-full flex flex-col items-center justify-start max-w-xl mt-4">

    </div>

  </div>
</template>

<script setup>

import { onMounted } from 'vue'

import rollbar from '../rollbarClient.js'
import DynamicChart from '../components/charts/DynamicChart.vue'
import Hero from '../components/Hero.vue'
import EmailSubscriber from '@shared/components/EmailSubscriber.vue'


import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

async function logVisit() {
  rollbar.info("printable landing page visited")
}

async function logPDF(tz) {
  rollbar.info(`pdf tap`, { fingerprint: `${tz}` })
}

onMounted(() => {
  logVisit()
})

function scrollToElement(elem) {
  const element = document.getElementById(elem)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', })
  }

}

</script>
