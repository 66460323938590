<template>

    <div v-if="!sub.subscriberStatus" class="w-full flex flex-col justify-center items-start">
        <form @submit.prevent="onSubmit" class="w-full flex space-x-3">
            <input type="email" name="email" id="subscriber_email" :placeholder="userContent.placeholder" required
                autocomplete="email" aria-label="Subscriber email address"
                class="w-full h-12 border border-black rounded-lg px-4" v-model="subscriberEmail">

            </input>
            <button @click="dispatchCreateSubscriber"
                class="w-24 h-12 border rounded-lg  text-white bg-black text-sm px-4  border-black flex justify-center items-center">
                <LoadSpin class="w-6 h-6" v-if="isLoading" />
                <span v-else>{{ userContent.subscribe }}</span>
            </button>

        </form>
        <div class="text-xs text-gray-500 mt-1 pl-2">{{userContent.privacy}} <button class="underline" @click="ui.openRightTray(markRaw(Privacy))">{{ userContent.privacyPolicy }}</button></div>
    </div>
    <div v-else-if="sub.subscriberStatus == 'pending' || sub.subscriberStatus == 'new'"
        class="w-full flex flex-col justify-center items-start">
        <div
            class="w-full h-12 border border-black rounded-lg flex flex-col justify-center items-center text-sm space-x-3 relative">
            <div class="font-medium">{{ userContent.pendingConfirmation }}</div>
            <button @click="dispatchRemoveSubscriber"
                class="h-4 w-26 text-white absolute bottom-0 right-0 px-2 py-1 text-xs cursor-pointer bg-black  rounded-br-md rounded-tl-md flex justify-center items-center">
                <LoadSpin class="w-3 h-3" v-if="isLoading" />
                <span v-else>{{ userContent.unsubscribe }}</span>
            </button>
        </div>
    </div>
    <div v-else-if="sub.subscriberStatus == 'confirmed'" class="w-full flex flex-col justify-center items-start">
        <div
            class="w-full h-12 border border-black rounded-lg flex flex-col justify-center items-center text-sm space-x-3 relative">
            <div class="font-medium">{{ userContent.thanks }}</div>
            <button @click="dispatchRemoveSubscriber"
                class="h-4 w-26 text-white absolute bottom-0 right-0 px-2 py-1 text-xs cursor-pointer bg-black  rounded-br-md rounded-tl-md flex justify-center items-center">
                <LoadSpin class="w-3 h-3" v-if="isLoading" />
                <span v-else>{{ userContent.unsubscribe }}</span>
            </button>
        </div>
    </div>
    <div v-else-if="sub.subscriberStatus == 'removed'" class="w-full flex flex-col justify-center items-start">
        <div
            class="w-full h-12 border border-black rounded-lg flex flex-col justify-center items-center text-sm space-x-3 relative">
            <div class="font-medium">{{ userContent.unsubscribed}}</div>
            <button @click="dispatchResubscribe"
                class="h-4 w-26 text-white absolute bottom-0 right-0 px-2 py-1 text-xs cursor-pointer bg-black  rounded-br-md rounded-tl-md flex justify-center items-center">{{ userContent.resubscribe }}</button>
        </div>
    </div>

</template>

<script setup>

import { ref, onMounted, markRaw } from 'vue'

import { useSubscriberStore } from '@shared/stores/subscriber.js'
const sub = useSubscriberStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import Privacy from '@shared/components/Privacy.vue'
import LoadSpin from '@shared/components/LoadSpin.vue'

// import content from '@shared/content/content.js'

const subscriberEmail = ref()

const isLoading = ref(false)

const userContent = getContent()

function getContent() {
    const defaultLang = 'en'
    const userLang = navigator.language
    if (content[userLang]) {
        return content[userLang]['subscriber']
    } else
        return content[defaultLang]['subscriber']
}


async function dispatchCreateSubscriber() {
    isLoading.value = true
    const resp = await sub.createSubscriber(subscriberEmail.value)
    if (resp) {
        subscriberEmail.value = ''

    }
    isLoading.value = false
}

async function dispatchRemoveSubscriber() {
    isLoading.value = true

    const unsubCode = localStorage.getItem('unsubCode')
    const resp = await sub.removeSubscriber(unsubCode)
    if (resp) {
        subscriberEmail.value = ''
    }
    isLoading.value = false

}

function dispatchResubscribe() {
    sub.clearSubscriber()
}

onMounted(() => {
    sub.readSubscriber()
})


</script>