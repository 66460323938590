import { ref } from "vue"
import { defineStore } from "pinia"
import api from '../api/api.js'


export const useSubscriberStore = defineStore('subscriberStore', () => {

    const subscriberStatus = ref(null)

    function readSubscriber() {
        const subStatus = localStorage.getItem('subscriberStatus')
        if (subStatus) {
            subscriberStatus.value = subStatus
        }
    }

    function writeSubscriber(status, unsubCode) {
        subscriberStatus.value = status
        localStorage.setItem('subscriberStatus', status)
        localStorage.setItem('unsubCode', unsubCode)
    }

    function clearSubscriber() {
        subscriberStatus.value = null

        localStorage.removeItem('subscriberStatus')
        localStorage.removeItem('unsubCode')
    }

    async function createSubscriber(email) {
        try {
            const resp = await api.postCreateSubscriber(email)
            if (resp) {
                writeSubscriber(resp.headers['x-subscriber-status'], resp.data.data['unsub_code'])
            }
        } catch (error) {
            console.error(`error in creating subscriber: ${error}`)
        }
    }

    async function confirmSubscriber(confirmCode) {
        try {
            const resp = await api.patchConfirmSubscriber(confirmCode)
            if (resp) {
                writeSubscriber(resp.headers['x-subscriber-status'], resp.data.data['unsub_code'])
            }
        } catch (error) {
            localStorage.clear('subscriberStatus')
        }
    }

    async function removeSubscriber(unsubCode) {
        try {
            const resp = await api.deleteRemoveSubscriber(unsubCode)
            if (resp.status == 200) {
                writeSubscriber('removed', 'null')
            }
        } catch (error) {
            clearSubscriber()
        }
    }


    return {
        subscriberStatus,
        readSubscriber,
        writeSubscriber,
        createSubscriber,
        confirmSubscriber,
        removeSubscriber,
        clearSubscriber
    }

})