<template>

<div class="w-full grid sm:grid-cols-2 gap-7 sm:px-12 mt-14 pb-8">
    <img class="rounded-lg shadow-lg" v-for="image in images" :key="image" :src="image">
</div>
</template>

<script setup>

import { ref } from 'vue'

const images = ref([
    '/partner/post_example.png',
    '/partner/big_up_OP.png',
    '/partner/capes.png',
    '/partner/daughter_cope.png',
    '/partner/aest_request.png',
  '/partner/backgrounds_are_annoying.png',
  '/partner/cdt_request.png',
  '/partner/dad_thanks.png',
  '/partner/daughters.png',
  '/partner/eest_request.png',
//   '/partner/euro_2024_partner_example.png',
  '/partner/hkt_request.png',
  '/partner/legible_a4_win.png',
  '/partner/mum.png',
  '/partner/myanmar_request.png',
  '/partner/nzt_thanks.png',
  '/partner/perfection.png',
  '/partner/pst.png',
  '/partner/scot_in_seoul.png',
  '/partner/sgt_request.png',
  '/partner/son_nice_people.png',
  '/partner/thanks_block_colour.png',
  '/partner/timezone_thanks.png',
  '/partner/uk.png',
  '/partner/vietnam_request.png'
])
</script>

