export default [
    { code: "am", description: "Amharic", nativeDescription: "አማርኛ" },
    { code: "ar", description: "Arabic", nativeDescription: "العربية" },
    { code: "ar-AE", description: "Arabic (United Arab Emirates)", nativeDescription: "العربية (الإمارات العربية المتحدة)" },
    { code: "ar-DZ", description: "Arabic (Algeria)", nativeDescription: "العربية (الجزائر)" },
    { code: "ar-EG", description: "Arabic (Egypt)", nativeDescription: "العربية (مصر)" },
    { code: "ar-MA", description: "Arabic (Morocco)", nativeDescription: "العربية (المغرب)" },
    { code: "ar-SA", description: "Arabic (Saudi Arabia)", nativeDescription: "العربية (المملكة العربية السعودية)" },
    { code: "bn", description: "Bengali", nativeDescription: "বাংলা" },
    { code: "bn-BD", description: "Bengali (Bangladesh)", nativeDescription: "বাংলা (বাংলাদেশ)" },
    { code: "bn-IN", description: "Bengali (India)", nativeDescription: "বাংলা (ভারত)" },
    { code: "de", description: "German", nativeDescription: "Deutsch" },
    { code: "de-AT", description: "German (Austria)", nativeDescription: "Deutsch (Österreich)" },
    { code: "de-CH", description: "German (Switzerland)", nativeDescription: "Deutsch (Schweiz)" },
    { code: "de-DE", description: "German (Germany)", nativeDescription: "Deutsch (Deutschland)" },
    { code: "en", description: "English", nativeDescription: "English" },
    { code: "en-AU", description: "English (Australia)", nativeDescription: "English (Australia)" },
    { code: "en-CA", description: "English (Canada)", nativeDescription: "English (Canada)" },
    { code: "en-GB", description: "English (United Kingdom)", nativeDescription: "English (United Kingdom)" },
    { code: "en-IN", description: "English (India)", nativeDescription: "English (India)" },
    { code: "en-NZ", description: "English (New Zealand)", nativeDescription: "English (New Zealand)" },
    { code: "en-US", description: "English (United States)", nativeDescription: "English (United States)" },
    { code: "en-ZA", description: "English (South Africa)", nativeDescription: "English (South Africa)" },
    { code: "es", description: "Spanish", nativeDescription: "Español" },
    { code: "es-AR", description: "Spanish (Argentina)", nativeDescription: "Español (Argentina)" },
    { code: "es-CL", description: "Spanish (Chile)", nativeDescription: "Español (Chile)" },
    { code: "es-CO", description: "Spanish (Colombia)", nativeDescription: "Español (Colombia)" },
    { code: "es-ES", description: "Spanish (Spain)", nativeDescription: "Español (España)" },
    { code: "es-MX", description: "Spanish (Mexico)", nativeDescription: "Español (México)" },
    { code: "es-PE", description: "Spanish (Peru)", nativeDescription: "Español (Perú)" },
    { code: "es-VE", description: "Spanish (Venezuela)", nativeDescription: "Español (Venezuela)" },
    { code: "fa", description: "Persian", nativeDescription: "فارسی" },
    { code: "fa-AF", description: "Dari (Afghanistan)", nativeDescription: "دری (افغانستان)" },
    { code: "fa-IR", description: "Persian (Iran)", nativeDescription: "فارسی (ایران)" },
    { code: "ff", description: "Fula (Fulani)", nativeDescription: "Fulfulde" },
    { code: "fil", description: "Filipino", nativeDescription: "Filipino" },
    { code: "fr", description: "French", nativeDescription: "Français" },
    { code: "fr-BE", description: "French (Belgium)", nativeDescription: "Français (Belgique)" },
    { code: "fr-CA", description: "French (Canada)", nativeDescription: "Français (Canada)" },
    { code: "fr-CH", description: "French (Switzerland)", nativeDescription: "Français (Suisse)" },
    { code: "fr-FR", description: "French (France)", nativeDescription: "Français (France)" },
    { code: "gu", description: "Gujarati", nativeDescription: "ગુજરાતી" },
    { code: "ha", description: "Hausa", nativeDescription: "Hausa" },
    { code: "hi", description: "Hindi", nativeDescription: "हिन्दी" },
    { code: "id", description: "Indonesian", nativeDescription: "Bahasa Indonesia" },
    { code: "ig", description: "Igbo", nativeDescription: "Igbo" },
    { code: "it", description: "Italian", nativeDescription: "Italiano" },
    { code: "it-IT", description: "Italian (Italy)", nativeDescription: "Italiano (Italia)" },
    { code: "ja", description: "Japanese", nativeDescription: "日本語" },
    { code: "kn", description: "Kannada", nativeDescription: "ಕನ್ನಡ" },
    { code: "ko", description: "Korean", nativeDescription: "한국어" },
    { code: "ml", description: "Malayalam", nativeDescription: "മലയാളം" },
    { code: "mr", description: "Marathi", nativeDescription: "मराठी" },
    { code: "ms", description: "Malay", nativeDescription: "Bahasa Melayu" },
    { code: "ms-BN", description: "Malay (Brunei)", nativeDescription: "Bahasa Melayu (Brunei)" },
    { code: "ms-MY", description: "Malay (Malaysia)", nativeDescription: "Bahasa Melayu (Malaysia)" },
    { code: "my", description: "Burmese", nativeDescription: "မြန်မာဘာသာ" },
    { code: "nl", description: "Dutch", nativeDescription: "Nederlands" },
    { code: "nl-BE", description: "Dutch (Belgium)", nativeDescription: "Nederlands (België)" },
    { code: "nl-NL", description: "Dutch (Netherlands)", nativeDescription: "Nederlands (Nederland)" },
    { code: "om", description: "Oromo", nativeDescription: "Afaan Oromoo" },
    { code: "pa", description: "Punjabi", nativeDescription: "ਪੰਜਾਬੀ" },
    { code: "pa-IN", description: "Punjabi (India)", nativeDescription: "ਪੰਜਾਬੀ (ਭਾਰਤ)" },
    { code: "pa-PK", description: "Punjabi (Pakistan)", nativeDescription: "پنجابی (پاکستان)" },
    { code: "pl", description: "Polish", nativeDescription: "Polski" },
    { code: "pt", description: "Portuguese", nativeDescription: "Português" },
    { code: "pt-BR", description: "Portuguese (Brazil)", nativeDescription: "Português (Brasil)" },
    { code: "pt-PT", description: "Portuguese (Portugal)", nativeDescription: "Português (Portugal)" },
    { code: "ru", description: "Russian", nativeDescription: "Русский" },
    { code: "sn", description: "Shona", nativeDescription: "ChiShona" },
    { code: "so", description: "Somali", nativeDescription: "Soomaaliga" },
    { code: "sw", description: "Swahili", nativeDescription: "Kiswahili" },
    { code: "sw-KE", description: "Swahili (Kenya)", nativeDescription: "Kiswahili (Kenya)" },
    { code: "sw-TZ", description: "Swahili (Tanzania)", nativeDescription: "Kiswahili (Tanzania)" },
    { code: "ta", description: "Tamil", nativeDescription: "தமிழ்" },
    { code: "te", description: "Telugu", nativeDescription: "తెలుగు" },
    { code: "th", description: "Thai", nativeDescription: "ไทย" },
    { code: "tr", description: "Turkish", nativeDescription: "Türkçe" },
    { code: "uk", description: "Ukrainian", nativeDescription: "Українська" },
    { code: "ur", description: "Urdu", nativeDescription: "اردو" },
    { code: "ur-IN", description: "Urdu (India)", nativeDescription: "اردو (بھارت)" },
    { code: "ur-PK", description: "Urdu (Pakistan)", nativeDescription: "اردو (پاکستان)" },
    { code: "vi", description: "Vietnamese", nativeDescription: "Tiếng Việt" },
    { code: "wo", description: "Wolof", nativeDescription: "Wolof" },
    { code: "yo", description: "Yoruba", nativeDescription: "Yorùbá" },
    { code: "zh", description: "Chinese", nativeDescription: "中文" },
    { code: "zh-CN", description: "Chinese (Simplified, China)", nativeDescription: "中文（简体，中国）" },
    { code: "zh-HK", description: "Chinese (Traditional, Hong Kong)", nativeDescription: "中文（繁體，香港）" },
    { code: "zh-TW", description: "Chinese (Traditional, Taiwan)", nativeDescription: "中文（繁體，台灣）" },
    { code: "zu", description: "Zulu", nativeDescription: "isiZulu" }
]
