import { ref, watch, computed } from "vue"
import { defineStore } from "pinia"
import  supportedLanguages  from '../content/supportedLanguages.js'

export const useLanguageStore = defineStore('languageStore', () => {


    const supported_langs = supportedLanguages

    const lang = ref('en')
    const isRTL = ref(false)

    const langSearchTerm = ref('')

    function selectLanguage(l) {
        langSearchTerm.value = ''
        lang.value = l.code
    }

    const filteredLanguages = computed(() => {
        const search = langSearchTerm.value.trim().toLowerCase() // trim whitespace and normalize case
        if (!search) return null


        return supported_langs.filter(lang =>
            lang.code.toLowerCase().includes(search) ||
            lang.description.toLowerCase().includes(search) ||
            lang.nativeDescription.toLowerCase().includes(search)
        )
    })

    const highlightSearch = (lang) => {
        const query = langSearchTerm.value
        if (!query) return `(${lang.code})`
        const regex = new RegExp(`(${query})`, 'gi')

        return `${lang.description} ${lang.nativeDescription}`.replace(regex, '$1');
    }

    function matchSupportedLang(browserLang) {
        if (supported_langs.includes(browserLang)) {
            return browserLang
        }

        const generalLang = browserLang.split('-')[0]
        return supported_langs.includes(generalLang) ? generalLang : 'en'
    }

    async function getLanguage() {
        const langPref = localStorage.getItem("langPref")

        if (langPref) {
            lang.value = langPref
        } else {
            const browserLang = navigator.language
            lang.value = matchSupportedLang(browserLang)
        }
        determineRTL()
    }

    watch(lang, (newVal, oldVal) => {
        if (newVal != oldVal) {
           localStorage.setItem("langPref", newVal)
           determineRTL()

        }
    })

    function determineRTL() {
        const rtlLanguages = ['ar', 'fa', 'he', 'ur', 'om']
        isRTL.value = rtlLanguages.includes(lang.value.split('-')[0])
    }


    return {
        lang,
        langSearchTerm,
        isRTL,
        supported_langs,
        getLanguage,
        selectLanguage,
        highlightSearch,
        filteredLanguages,
        determineRTL
    }

})