import { ref, watch } from "vue"
import { defineStore } from "pinia"
import { useLocationStore } from '@shared/stores/location.js';
import { useLanguageStore } from '@shared/stores/language.js'

import { translatedTeams } from '../content/teamNames.js'

import { DateTime } from 'luxon'

export const useScheduleStore = defineStore('scheduleStore', () => {

    const location = useLocationStore()
    const language = useLanguageStore()

    const gameSchedule = ref({
        m1: {
            gameNumber: '#1',
            home: 'ENG',
            away: 'SCO',
            gameUTCDateTime: '2024-07-01T18:00:00Z',
            home_translated: 'ENG',
            away_translated: 'SCO',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m2: {
            gameNumber: '#2',
            home: 'ENG',
            away: 'ITA',
            gameUTCDateTime: '2024-07-02T17:00:00Z',
            home_translated: 'ENG',
            away_translated: 'SCO',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m3: {
            gameNumber: '#3',
            home: 'GER',
            away: 'ENG',
            gameUTCDateTime: '2024-07-03T17:00:00Z',
            home_translated: 'ENG',
            away_translated: 'SCO',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },
        m4: {
            gameNumber: '#4',
            home: 'GER',
            away: 'ITA',
            gameUTCDateTime: '2024-07-03T20:00:00Z',
            home_translated: 'ENG',
            away_translated: 'SCO',
            gameAdjustedDateTime: '',
            game_date: '',
            game_time: ''
        },

    })

    const adjustGameDateTime = (gameUTCDateTime) => {

        const adjusted = DateTime.fromISO(gameUTCDateTime, { zone: 'utc' }).setZone(location.selected_timezone)

        return adjusted
    }

    function translateTeam(teamName, lang) {

        try {
            return translatedTeams[teamName][lang]
        } catch {
            return teamName
        }
    }



    function localiseGameSchedule(gameSchedule) {
        Object.keys(gameSchedule).forEach(key => {

            gameSchedule[key]['gameAdjustedDateTime'] = adjustGameDateTime(gameSchedule[key]['gameUTCDateTime'])
            gameSchedule[key]['home_translated'] = translateTeam(gameSchedule[key]['home'], language.lang)
            gameSchedule[key]['away_translated'] = translateTeam(gameSchedule[key]['away'], language.lang)
            gameSchedule[key]['game_date'] = gameSchedule[key]['gameAdjustedDateTime'].setLocale(language.lang).toLocaleString({month: 'numeric', day: 'numeric', weekday: 'short'})
            gameSchedule[key]['game_time'] = gameSchedule[key]['gameAdjustedDateTime'].setLocale(language.lang).toLocaleString(DateTime.TIME_SIMPLE)
        })

        return gameSchedule
    }



    watch(() => [language.lang, location.selected_timezone], (newVal, oldVal) => {
        localiseGameSchedule(gameSchedule.value, location.selected_timezone)
    })

    return {
        gameSchedule,
        localiseGameSchedule
    }
})