import Rollbar from 'rollbar';
// import config from '../rollbar.config';

let rollbar

if (import.meta.env.VITE_CURRENT_ENV === 'PRODUCTION') {
    rollbar = new Rollbar(
        {
            accessToken: import.meta.env.VITE_ROLLBAR,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: "PRODUCTION"
            }
        }
    );
} else {
    rollbar =
        {
            log: ()  => {},
            error: () => {},
            warn: () => {},
            info: () => {},
            critical: () => {},
        }
}

export default rollbar;