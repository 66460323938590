<template>

    <div class="w-full mt-20 flex justify-center items-center border border-red-400 ">
        Redirecting you to your country store in {{ language.lang }}
    </div>

</template>

<script setup>

import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useChartStore } from '../stores/chart.js'
const chart = useChartStore()

import { useUIStore } from '@shared/stores/ui.js'
const ui = useUIStore()

import { useLanguageStore } from '@shared/stores/language.js'
const language = useLanguageStore()

const router = useRouter()
const route = useRoute()

onMounted(() => {

      // http://localhost:5173/qr?competition=euro2025&partnerType=goodCause&countryCode=fr


        const competition = route.query.competition
        const partnerType = route.query.partnerType
        const countryCode = route.query.countryCode
        const redirectTarget = chart.lookupRedirect(competition, partnerType, countryCode)

        const payload = {
            competition: competition,
            partnerType: partnerType,
            countryCode: countryCode,
            lang: language.lang,
            redirectTarget: redirectTarget
        }
        console.log("fire off Send Beacon here", payload)

        if (redirectTarget) {
          window.location.href = redirectTarget
        } else {
          console.warn("No valid redirect found, redirecting to fallback page")
          ui.showMessage({message: "Sorry, couldn't complete redirect 😩", isError: true})
          router.push({ name: "home" })
        }
      })


</script>