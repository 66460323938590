import { ref, watch, computed } from "vue"
import { defineStore } from "pinia"
import api from '../api/api.js'
import { DateTime } from 'luxon'
import moment from 'moment-timezone';
import ct from 'countries-and-timezones'

export const useLocationStore = defineStore('locationStore', () => {

    const deviceCountry = ref(null)
    const deviceCountryCode = ref(null)
    const deviceRegion = ref(null)
    const deviceRegionName = ref(null)
    const deviceCity = ref(null)
    const deviceTimezone = ref(null)
    const deviceOffset = ref(null)

    const selected_timezone = ref(null)


    const lookupCountryCode = (timezone) => {
        const country = ct.getCountryForTimezone(timezone)

        return country.id // id is countryCode
    }

    // Function to get formatted timezone details
    function getTimezoneDetails() {
        // const timezones = moment.tz.names()
        // return timezones
        const timezones = moment.tz.names().map(zoneName => {
            const currentTime = moment().tz(zoneName)
            const offset = currentTime.utcOffset() // Offset in minutes
            const hoursOffset = offset / 60 // Convert minutes to hours
            const formattedOffset = `UTC${hoursOffset >= 0 ? '+' : ''}${hoursOffset}`

            // Map timezones to display friendly name (e.g., "America/New_York (UTC-5)")
            return {
                zone: zoneName,
                label: `${zoneName.replace('_', ' ')} (${formattedOffset})`,
                offset: formattedOffset,

            }
        })
        return timezones
    }


    const tzSearchTerm = ref('')

    function selectTimezone(tz) {
        tzSearchTerm.value = ''
        selected_timezone.value = tz.zone
        console.log(lookupCountryCode(tz.zone))
    }

    const filteredTimezones = computed(() => {
        const search = tzSearchTerm.value.trim().toLowerCase() // trim whitespace and normalize case
        if (!search) return null

        const timezones = getTimezoneDetails()
        return timezones.filter(tz =>
            tz.zone.toLowerCase().includes(search) ||
            tz.label.toLowerCase().includes(search) ||
            tz.offset.toLowerCase().includes(search)
        )
    })

    const highlightSearch = (tz) => {
        const query = tzSearchTerm.value;  // Get the current search query
        if (!query) return `(${tz.zone})`;  // If no search query, return the city and zone as plain text

        // Create a regular expression to find occurrences of the search query in the text
        const regex = new RegExp(`(${query})`, 'gi');

        // Return the city and zone with matched parts of the query wrapped in <mark> tags
        return `${tz.zone} ${tz.offset}`.replace(regex, '$1');
    }

    const get_oset = (zone) => {
        // Get current time in the specified zone with Luxon
        const nowInZone = DateTime.now().setZone(zone);

        // Format the offset to something like UTC+1 or UTC-5
        const offset = nowInZone.offset / 60;  // Offset in hours

        // Determine if the zone is in DST or not
        const isDST = nowInZone.isInDST;
        const offsetString = offset >= 0 ? `UTC+${offset}` : `UTC${offset}`;

        // Return offset with an indication if it's currently in DST
        return isDST ? `${offsetString} (DST)` : offsetString;
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getLocation() {
        const timezonePref = localStorage.getItem('timezonePref')


        if (timezonePref) {
            selected_timezone.value = timezonePref
        } else {
            await delay(3000)
            const locationResp = await api.getLocationFromApi()
            if (locationResp) {
                deviceCountry.value = locationResp.country
                deviceCountryCode.value = locationResp.countryCode
                deviceRegion.value = locationResp.region
                deviceRegionName.value = locationResp.regionName
                deviceCity.value = locationResp.city
                deviceTimezone.value = locationResp.timezone
                deviceOffset.value = locationResp.offset
                selected_timezone.value = locationResp.timezone
            } else {
                deviceCountry.value = "United Kingdom"
                deviceCountryCode.value = "GB"
                deviceRegion.value = "ENG"
                deviceRegionName.value = "England"
                deviceCity.value = "Manchester"
                deviceTimezone.value = "Europe/London"
                deviceOffset.value = 3600
                selected_timezone.value = 'Europe/London'
            }
        }
    }



    watch([selected_timezone], (newVal, oldVal) => {
        if (newVal != oldVal) {
            localStorage.setItem('timezonePref', newVal)
        }
    })

    return {
        // timezones,
        filteredTimezones,
        selected_timezone,
        tzSearchTerm,
        selectTimezone,
        getLocation,
        get_oset,
        highlightSearch,
        getTimezoneDetails,
        lookupCountryCode
    }

})