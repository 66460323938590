import axios from "axios"


const printableApi = axios.create({
    baseURL: import.meta.env.VITE_SERVER_ROOT,
    timeout: 10_000
})


export default {
    async postCreateChart(svgContent) {
        return printableApi.post(
            "/printable/charts",
            { svg_content: svgContent },
            {
                headers: {
                    'x-app-id': import.meta.env.VITE_APP_ID,
                    'Content-Type': 'application/json'
                }
            }
        )
    },

}
