<template>

    <button @click="handleClick" :disabled="isLoading" :class="buttonStyle" class="px-3 font-semibold">
        <div class="w-full flex justify-evenly items-center">
            <div v-if="!isLoading" class=" flex justify-evenly items-center w-full">

                <span>
                    {{ text }}
                </span>
                <component :is="icon" class="h-6" />
            </div>
            <div v-else
                class="spinner animate-spin">
            </div>
        </div>

    </button>
</template>

<script setup>

import { ref } from 'vue'
// import LoadSpin from '@shared/components/LoadSpin.vue'

const isLoading = ref(false)

const props = defineProps({
    parentFunction: Function,
    text: String,
    buttonStyle: String,
    icon: Object
})

const handleClick = async () => {
    isLoading.value = true
    try {
        await props.parentFunction()
        console.log('child button aciton')
    } catch (error) {
        console.log('in button component', error)
    } finally {
        isLoading.value = false
    }
}


</script>